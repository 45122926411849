import React from "react"

interface metadata {
  readonly id: string
  readonly URL: string
  readonly text: string
  readonly class?: string
  readonly bgColor?: string
  readonly bgHoverColor?: string
}

interface Props {
  readonly metadata: metadata
}

const CallToAction: React.FC<Props> = ({metadata}) => (
  <div className="text-center overflow-hidden py-2 md:py-3">
    <a
      id={metadata.id}
      href={metadata.URL}
      className={`transition duration-500 ease-in-out text-white py-2 rounded-sm font-bold inline-block w-full lg:w-1/2  ${
        metadata.bgColor || "bg-tercen-green-base"
      } ${metadata.bgHoverColor || "hover:bg-tercen-green-hover"} ${metadata.class}`}>
      {metadata.text}
    </a>
  </div>
)

export default CallToAction
